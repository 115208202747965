<template>
  <section id="map" class="fixed top-0 left-0 w-full h-full" @mousemove="onMouseMove">
    <div id="map-div"></div>
    <div
      id="welcome"
      class="absolute inline-block pl-50 pt-50 text-center"
      v-show="loggedIn && currentRouteName == 'home'"
    >
      <p class="sans">Your next practice<br />time starts at</p>
      <h1 v-html="nextTime"></h1>
    </div>

    <div v-show="false" id="sessionData" class="absolute bottom-10 right-10 inline-block pointer-events-auto">
      <div v-show="showSessionInfo">
        <div id="sessionMenu" class="grid grid-cols-3 gap-x-1 mb-1">
          <a @click="sessionDataClick('current')" :class="{ active: sessionTab == 'current' }">Current</a>
          <a @click="sessionDataClick('personal')" :class="{ active: sessionTab == 'personal' }">Personal</a>
          <!-- <a @click="sessionDataClick('today')" :class="{ active: sessionTab == 'today' }">Today</a> -->
        </div>
        <div class="bg-white flex p-2">
          <div v-show="sessionTab == 'personal'">
            <h2>Personal Practice Time</h2>
            <h4>Timezone:</h4>
            <p>{{ timezone }}</p>
            <h4>Total people:</h4>
          </div>
          <div v-show="sessionTab == 'current'">
            <h2>Current Practice Time</h2>
            <h4>Morning Timezone:</h4>
            <p>{{ currentMorningTZ.label }}</p>
            <h4>Evening Timezone:</h4>
            <p class="text-evening">{{ currentEveningTZ.label }}</p>
          </div>
          <div v-show="sessionTab == 'today'"></div>
        </div>
      </div>
      <div
        id="sessionInfoBtn"
        class="relative w-full flex m-auto justify-center mt-2 p-1 opacity-40"
        @click="showSessionInfo = !showSessionInfo"
      >
        <div class="pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="159.5px" height="8.8px" overflow="visible">
            <path v-show="!showSessionInfo" id="close_1_" class="st0" d="M143.2 8.8L151.3 0.6 159.5 8.8z" />
            <path v-show="showSessionInfo" id="open_1_" class="st0" d="M159.5 0.6L151.3 8.8 143.2 0.6z" />
            <g id="session_x5F_information_1_">
              <path
                class="st0"
                d="M1.3,6c0.6,0.6,1.4,1,2.5,1C4.8,7,5.4,6.6,5.4,6c0-0.5-0.4-0.7-0.8-0.9C3.9,4.9,3,4.7,2.1,4.4 C1.2,4,0.6,3.5,0.6,2.5c0-1.4,1.1-2.4,3.1-2.4c1.7,0,2.7,0.7,3.2,1.3L5.7,2.5C5.2,2,4.5,1.6,3.6,1.6c-0.8,0-1.3,0.3-1.3,0.8 c0,0.4,0.3,0.6,0.7,0.7c0.7,0.3,1.7,0.4,2.5,0.7c0.9,0.3,1.6,0.9,1.6,2.2c0,1.7-1.4,2.6-3.5,2.6c-1.8,0-3-0.7-3.6-1.5L1.3,6z"
              />
              <path class="st0" d="M13.9,0.2v1.6h-3.7v1.7h2.6V5h-2.6v1.8H14v1.6H8.5V0.2H13.9z" />
              <path
                class="st0"
                d="M16.2,6c0.6,0.6,1.4,1,2.5,1c1.1,0,1.7-0.4,1.7-1.1c0-0.5-0.4-0.7-0.8-0.9c-0.7-0.2-1.6-0.4-2.5-0.7 c-0.9-0.4-1.5-0.9-1.5-1.9c0-1.4,1.1-2.4,3.1-2.4c1.7,0,2.7,0.7,3.2,1.3l-1.2,1.1c-0.5-0.5-1.2-0.8-2.1-0.8c-0.8,0-1.3,0.3-1.3,0.8 c0,0.4,0.3,0.6,0.7,0.7c0.7,0.3,1.7,0.4,2.5,0.7C21.3,4.1,22,4.7,22,5.9c0,1.7-1.4,2.6-3.5,2.6c-1.8,0-3-0.7-3.6-1.5L16.2,6z"
              />
              <path
                class="st0"
                d="M24,6c0.6,0.6,1.4,1,2.5,1c1.1,0,1.7-0.4,1.7-1.1c0-0.5-0.4-0.7-0.8-0.9c-0.7-0.2-1.6-0.4-2.5-0.7 C24,4,23.3,3.5,23.3,2.5c0-1.4,1.1-2.4,3.1-2.4c1.7,0,2.7,0.7,3.2,1.3l-1.2,1.1c-0.5-0.5-1.2-0.8-2.1-0.8c-0.8,0-1.3,0.3-1.3,0.8 c0,0.4,0.3,0.6,0.7,0.7c0.7,0.3,1.7,0.4,2.5,0.7c0.9,0.3,1.6,0.9,1.6,2.2c0,1.7-1.4,2.6-3.5,2.6c-1.8,0-3-0.7-3.6-1.5L24,6z"
              />
              <path class="st0" d="M33,0.2v8.3h-1.8V0.2H33z" />
              <path
                class="st0"
                d="M42.2,4.3c0,2.8-1.6,4.3-3.8,4.3c-2.2,0-3.8-1.5-3.8-4.3c0-2.8,1.6-4.3,3.8-4.3C40.6,0,42.2,1.5,42.2,4.3z M36.4,4.3c0,1.8,0.8,2.7,2,2.7c1.2,0,2-0.9,2-2.7s-0.8-2.7-2-2.7C37.2,1.6,36.4,2.5,36.4,4.3z"
              />
              <path class="st0" d="M45.7,0.2l3.2,5.1V0.2h1.7v8.3H49l-3.3-5v5h-1.8V0.2H45.7z" />
              <path class="st0" d="M57.4,0.2v8.3h-1.8V0.2H57.4z" />
              <path class="st0" d="M61.1,0.2l3.2,5.1V0.2H66v8.3h-1.6l-3.3-5v5h-1.8V0.2H61.1z" />
              <path class="st0" d="M73.1,0.2v1.6h-3.5v1.8h2.5v1.6h-2.5v3.3h-1.8V0.2H73.1z" />
              <path
                class="st0"
                d="M81.6,4.3c0,2.8-1.6,4.3-3.8,4.3c-2.2,0-3.8-1.5-3.8-4.3c0-2.8,1.6-4.3,3.8-4.3C80,0,81.6,1.5,81.6,4.3z M75.8,4.3c0,1.8,0.8,2.7,2,2.7c1.2,0,2-0.9,2-2.7s-0.8-2.7-2-2.7C76.6,1.6,75.8,2.5,75.8,4.3z"
              />
              <path
                class="st0"
                d="M86.8,0.2c1.8,0,3,0.9,3,2.6c0,1.2-0.6,2-1.6,2.4l2,3.3h-2l-1.8-3.1h-1.4v3.1h-1.8V0.2H86.8z M85,3.9h1.8 c0.8,0,1.3-0.3,1.3-1.1c0-0.7-0.5-1.1-1.3-1.1H85V3.9z"
              />
              <path class="st0" d="M93.2,0.2l2.4,5.3L98,0.2h1.7v8.3h-1.8V4.2l-2,4.3h-0.7l-2-4.3v4.3h-1.8V0.2H93.2z" />
              <path
                class="st0"
                d="M105.6,0.2l3.1,8.3h-1.9l-0.6-1.6H103l-0.5,1.6h-1.8l3-8.3H105.6z M103.6,5.3h2.1l-1.1-3.1L103.6,5.3z"
              />
              <path class="st0" d="M114.8,0.2v1.6h-2.4v6.7h-1.8V1.8h-2.4V0.2H114.8z" />
              <path class="st0" d="M117.8,0.2v8.3h-1.8V0.2H117.8z" />
              <path
                class="st0"
                d="M127.1,4.3c0,2.8-1.6,4.3-3.8,4.3s-3.8-1.5-3.8-4.3c0-2.8,1.6-4.3,3.8-4.3S127.1,1.5,127.1,4.3z M121.3,4.3 c0,1.8,0.8,2.7,2,2.7c1.2,0,2-0.9,2-2.7s-0.8-2.7-2-2.7C122,1.6,121.3,2.5,121.3,4.3z"
              />
              <path class="st0" d="M130.5,0.2l3.2,5.1V0.2h1.7v8.3h-1.6l-3.3-5v5h-1.8V0.2H130.5z" />
            </g>
          </svg>
        </div>
      </div>
      <!-- <img src="@/assets/img/home/fpo_momentLegend.png" alt="" class="fpo" /> -->
    </div>
  </section>
</template>

<script>
// import ThreejsLayer from '@/scripts/map/threejs-layer.js'
import * as THREE from 'three';

// import { Detector } from '../scripts/map/detector.js';

export default {
  props: {
    // title: {
    //   type: String,
    //   default: ""
    // },
    // content: {
    //   type: String,
    //   default: ""
    // },
    // footer: {
    //   type: String,
    //   default: ""
    // }
  },
  components: {},
  data() {
    return {
      timeHandle: null,
      eveningTime: null,
      morningTime: null,
      currentTime: null,
      nextTime: null,
      loggedIn: false,
      showSessionInfo: false,
      sessionTab: 'current',
      currentRouteName: 'home',
      locationChecked: false,
      fallback: {},
      usersLoc: [],
      tzOffset: 0,
      timezone: '',
      currentEveningTZ: { offset: '', label: '' },
      currentMorningTZ: { offset: '', label: '' },
      title: this.title,
      content: this.content,
      footer: this.footer,
      tzLayer: null,
      width: window.innerWidth,
      height: window.innerHeight,
      pWidth: 2.0,
      pHeight: 1.0,
      segments: 32,
      rotation: 6,
      container: null,
      scene: null,
      camera: null,
      plane: {},
      light: null,
      offsetList: [
        { offset: '-12', label: '(GMT-12:00) International Date Line West' },
        { offset: '-11', label: '(GMT-11:00) Midway Island, Samoa' },
        { offset: '-10', label: '(GMT-10:00) Hawaii' },
        { offset: '-9', label: '(GMT-09:00) Alaska' },
        { offset: '-8', label: '(GMT-08:00) Pacific Time (US &amp; Canada)' },
        { offset: '-8', label: '(GMT-08:00) Tijuana, Baja California' },
        { offset: '-7', label: '(GMT-07:00) Arizona' },
        { offset: '-7', label: '(GMT-07:00) Chihuahua, La Paz, Mazatlan' },
        { offset: '-7', label: '(GMT-07:00) Mountain Time (US &amp; Canada)' },
        { offset: '-6', label: '(GMT-06:00) Central America' },
        { offset: '-6', label: '(GMT-06:00) Central Time (US &amp; Canada)' },
        { offset: '-6', label: '(GMT-06:00) Guadalajara, Mexico City, Monterrey' },
        { offset: '-6', label: '(GMT-06:00) Saskatchewan' },
        { offset: '-5', label: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco' },
        { offset: '-5', label: '(GMT-05:00) Eastern Time (US &amp; Canada)' },
        { offset: '-5', label: '(GMT-05:00) Indiana (East)' },
        { offset: '-4', label: '(GMT-04:00) Atlantic Time (Canada)' },
        { offset: '-4', label: '(GMT-04:00) Caracas, La Paz' },
        { offset: '-4', label: '(GMT-04:00) Manaus' },
        { offset: '-4', label: '(GMT-04:00) Santiago' },
        { offset: '-3.5', label: '(GMT-03:30) Newfoundland' },
        { offset: '-3', label: '(GMT-03:00) Brasilia' },
        { offset: '-3', label: '(GMT-03:00) Buenos Aires, Georgetown' },
        { offset: '-3', label: '(GMT-03:00) Greenland' },
        { offset: '-3', label: '(GMT-03:00) Montevideo' },
        { offset: '-2', label: '(GMT-02:00) Mid-Atlantic' },
        { offset: '-1', label: '(GMT-01:00) Cape Verde Is.' },
        { offset: '-1', label: '(GMT-01:00) Azores' },
        { offset: '0', label: '(GMT+00:00) Casablanca, Monrovia, Reykjavik' },
        { offset: '0', label: '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London' },
        { offset: '1', label: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna' },
        { offset: '1', label: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague' },
        { offset: '1', label: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris' },
        { offset: '1', label: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb' },
        { offset: '1', label: '(GMT+01:00) West Central Africa' },
        { offset: '2', label: '(GMT+02:00) Amman' },
        { offset: '2', label: '(GMT+02:00) Athens, Bucharest, Istanbul' },
        { offset: '2', label: '(GMT+02:00) Beirut' },
        { offset: '2', label: '(GMT+02:00) Cairo' },
        { offset: '2', label: '(GMT+02:00) Harare, Pretoria' },
        { offset: '2', label: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius' },
        { offset: '2', label: '(GMT+02:00) Jerusalem' },
        { offset: '2', label: '(GMT+02:00) Minsk' },
        { offset: '2', label: '(GMT+02:00) Windhoek' },
        { offset: '3', label: '(GMT+03:00) Kuwait, Riyadh, Baghdad' },
        { offset: '3', label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd' },
        { offset: '3', label: '(GMT+03:00) Nairobi' },
        { offset: '3', label: '(GMT+03:00) Tbilisi' },
        { offset: '3.5', label: '(GMT+03:30) Tehran' },
        { offset: '4', label: '(GMT+04:00) Abu Dhabi, Muscat' },
        { offset: '4', label: '(GMT+04:00) Baku' },
        { offset: '4', label: '(GMT+04:00) Yerevan' },
        { offset: '4.5', label: '(GMT+04:30) Kabul' },
        { offset: '5', label: '(GMT+05:00) Yekaterinburg' },
        { offset: '5', label: '(GMT+05:00) Islamabad, Karachi, Tashkent' },
        { offset: '5.5', label: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
        { offset: '5.5', label: '(GMT+05:30) Sri Jayawardenapura' },
        { offset: '5.75', label: '(GMT+05:45) Kathmandu' },
        { offset: '6', label: '(GMT+06:00) Almaty, Novosibirsk' },
        { offset: '6', label: '(GMT+06:00) Astana, Dhaka' },
        { offset: '6.5', label: '(GMT+06:30) Yangon (Rangoon)' },
        { offset: '7', label: '(GMT+07:00) Bangkok, Hanoi, Jakarta' },
        { offset: '7', label: '(GMT+07:00) Krasnoyarsk' },
        { offset: '8', label: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi' },
        { offset: '8', label: '(GMT+08:00) Kuala Lumpur, Singapore' },
        { offset: '8', label: '(GMT+08:00) Irkutsk, Ulaan Bataar' },
        { offset: '8', label: '(GMT+08:00) Perth' },
        { offset: '8', label: '(GMT+08:00) Taipei' },
        { offset: '9', label: '(GMT+09:00) Osaka, Sapporo, Tokyo' },
        { offset: '9', label: '(GMT+09:00) Seoul' },
        { offset: '9', label: '(GMT+09:00) Yakutsk' },
        { offset: '9.5', label: '(GMT+09:30) Adelaide' },
        { offset: '9.5', label: '(GMT+09:30) Darwin' },
        { offset: '10', label: '(GMT+10:00) Brisbane' },
        { offset: '10', label: '(GMT+10:00) Canberra, Melbourne, Sydney' },
        { offset: '10', label: '(GMT+10:00) Hobart' },
        { offset: '10', label: '(GMT+10:00) Guam, Port Moresby' },
        { offset: '10', label: '(GMT+10:00) Vladivostok' },
        { offset: '11', label: '(GMT+11:00) Magadan, Solomon Is., New Caledonia' },
        { offset: '12', label: '(GMT+12:00) Auckland, Wellington' },
        { offset: '12', label: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.' },
        { offset: '13', label: "(GMT+13:00) Nuku'alofa" },
      ],
    };
  },
  mounted() {
    this.currentRouteName = this.$route.name;
    this.initTime();

    this.container = document.getElementById('map-div');
    this.setScene();

    this.loggedIn = this.$store.state.user.authenticated;
    this.$store.watch(
      (state) => {
        return state.user.authenticated;
      },
      ((newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.loggedIn = newVal;
          this.$forceUpdate();
        }
      }).bind(this),
      {}
    );

    this.$store.watch(
      (state) => {
        return state.timezone;
      },
      ((newVal, oldVal) => {
        if (newVal !== oldVal && newVal !== '') {
          this.timezone = newVal;
          // console.log(this.timezone);
        }
      }).bind(this),
      {}
    );
    this.checkSession();
  },
  computed: {
    // stripGMT(str) {
    strippedMorning() {
      var subs = this.currentMorningTZ.label.substring(
        this.currentMorningTZ.label.lastIndexOf('(') + 1,
        this.currentMorningTZ.label.lastIndexOf(')')
      );
      var retTxt = this.currentMorningTZ.label.replace(subs, '');
      retTxt = retTxt.replace('() ', '');
      return retTxt;
    },
    strippedEvening() {
      var subs = this.currentEveningTZ.label.substring(
        this.currentEveningTZ.label.lastIndexOf('(') + 1,
        this.currentEveningTZ.label.lastIndexOf(')')
      );
      var retTxt = this.currentEveningTZ.label.replace(subs, '');
      retTxt = retTxt.replace('() ', '');
      return retTxt;
    },
    // currentRouteName() {
    //   return this.$route.name;
    // },
    // showSessioninfo() {
    //   // console.log('showSessioninfo');
    //   return !['inspiration', 'favorites', 'login', 'signup', 'lost'].includes(this.currentRouteName);
    // },
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal.name !== oldVal.name) {
        this.currentRouteName = newVal.name;
        this.$forceUpdate();
      }
    },
  },
  methods: {
    initTime() {
      var today = new Date();
      this.currentTime = today;
      this.eveningTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 20, 55, 0);
      this.morningTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6, 55, 0);

      if (this.morningTime - this.currentTime < 0) {
        this.nextTime = '8:55<i>PM</i><span>(20.55)</span>';
      } else {
        this.nextTime = '6:55<i>AM</i><span>(06.55)</span>';
      }

      // var eveArray = [];
      // var mornArray = [];
      var mornRef = 0;
      var eveRef = 0;
      const eveHour = '9';
      const mornHour = '7';
      this.offsetList.forEach((v, i) => {
        var parseDateString = new Date(this.calcTime(v.offset)).toLocaleString('en-us').split(' ');
        // console.log(parseDateString);
        var parseTimeString = parseDateString[1].split(':');
        const parseDate = parseTimeString[0];
        if (parseDate == eveHour && parseDateString[2] == 'PM') {
          // console.log('eveHour',i);
          eveRef = i;
        }
        if (parseDate == mornHour && parseDateString[2] == 'AM') {
          // console.log('mornHour',i);
          mornRef = i;
        }
        // if (this.eveningTime > new Date(this.calcTime(v.offset))) {
        //   eveArray.push(this.eveningTime - new Date(this.calcTime(v.offset)));
        // }
        // if (this.morningTime > new Date(this.calcTime(v.offset))) {
        //   mornArray.push(new Date(this.calcTime(v.offset)) - this.morningTime);
        // }
      });

      // var currentEve = this.smallestPos(eveArray);
      // var currentMorn = this.smallestPos(mornArray);
      this.currentEveningTZ = this.offsetList[eveRef];
      this.currentMorningTZ = this.offsetList[mornRef];
    },
    smallestPos(arr) {
      var index = 0;
      var value = arr[0];
      for (var i = 1; i < arr.length; i++) {
        if (arr[i] < value) {
          value = arr[i];
          index = i;
        }
      }
      return { index: index, value: value };
    },
    checkSession() {
      var tokenAvail = window.localStorage.length;
      if (tokenAvail == 0) {
        return;
      } else {
        let userToken = localStorage.getItem('userTimezone');
        if (userToken) {
          // this.timezone = userToken;
          this.$store.dispatch('setState', { target: 'timezone', value: userToken });
          // this.$router.push('/');
        } else {
          this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
      }
    },
    calcTime(offset) {
      // create Date object for current location
      var d = new Date();

      // convert to msec
      // subtract local time zone offset
      // get UTC time in msec
      var utc = d.getTime() + d.getTimezoneOffset() * 60000;

      // create new Date object for different city
      // using supplied offset
      var nd = new Date(utc + 3600000 * offset);

      // return time as a string
      return nd.toLocaleString();
    },
    sessionDataClick(dataTab) {
      this.sessionTab = dataTab;
    },

    getTZArt(offset) {
      var texturePath;
      switch (offset) {
        case '-12':
          texturePath = 'tz_n_12.png';
          break;
        case '-11':
          texturePath = 'tz_n_11.png';
          break;
        case '-10':
          texturePath = 'tz_n_10.png';
          break;
        case '-9':
          texturePath = 'tz_n_9.png';
          break;
        case '-8':
          texturePath = 'tz_n_8.png';
          break;
        case '-7':
          texturePath = 'tz_n_7.png';
          break;
        case '-6':
          texturePath = 'tz_n_6.png';
          break;
        case '-5':
          texturePath = 'tz_n_5.png';
          break;
        case '-4':
          texturePath = 'tz_n_4.png';
          break;
        case '-3.5':
          texturePath = 'tz_n_3.png';
          break;
        case '-3':
          texturePath = 'tz_n_3.png';
          break;
        case '-2':
          texturePath = 'tz_n_2.png';
          break;
        case '-1':
          texturePath = 'tz_n_1.png';
          break;
        case '0':
          texturePath = 'tz_0.png';
          break;
        case '1':
          texturePath = 'tz_p_1.png';
          break;
        case '2':
          texturePath = 'tz_p_2.png';
          break;
        case '3':
          texturePath = 'tz_p_3.png';
          break;
        case '3.5':
          texturePath = 'tz_p_4.png';
          break;
        case '4':
          texturePath = 'tz_p_4.png';
          break;
        case '4.5':
          texturePath = 'tz_p_45.png';
          break;
        case '5':
          texturePath = 'tz_p_5.png';
          break;
        case '5.5':
          texturePath = 'tz_p_55.png';
          break;
        case '5.75':
          texturePath = 'tz_p_575.png';
          break;
        case '6':
          texturePath = 'tz_p_6.png';
          break;
        case '6.5':
          texturePath = 'tz_p_65.png';
          break;
        case '7':
          texturePath = 'tz_p_7.png';
          break;
        case '8':
          texturePath = 'tz_p_8.png';
          break;
        case '9':
          texturePath = 'tz_p_95.png';
          break;
        case '9.5':
          texturePath = 'tz_p_95.png';
          break;
        case '10':
          texturePath = 'tz_p_10.png';
          break;
        case '11':
          texturePath = 'tz_p_11.png';
          break;
        case '12':
          texturePath = 'tz_p_12.png';
          break;
        case '13':
          texturePath = 'tz_n_12.png';
          break;
      }
      // console.log(texturePath);
      return texturePath;
    },
    setScene() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;

      this.scene = new THREE.Scene();

      this.camera = new THREE.PerspectiveCamera(45, this.width / this.height, 0.01, 1000);
      this.camera.position.z = 1.1785056771673432;
      // const tzOff = (-1 * new Date().getTimezoneOffset()) / 60;
      const tzOff =
        this.morningTime - this.currentTime < 0 ? this.currentEveningTZ.offset : this.currentMorningTZ.offset;
      this.camera.position.x = tzOff / 12;
      this.renderer = new THREE.WebGLRenderer();
      this.renderer.setSize(this.width, this.height);

      this.scene.add(new THREE.AmbientLight(0xeeeeee));

      // this.light = new THREE.DirectionalLight(0xffffff, 0.75);
      // this.light.position.set(5, 3, 2.5);
      // this.scene.add(this.light);

      this.plane['1'] = this.createPlane(this.pWidth, this.pHeight, this.segments);
      this.plane['2'] = this.createPlane(this.pWidth, this.pHeight, this.segments);
      this.plane['3'] = this.createPlane(this.pWidth, this.pHeight, this.segments);

      this.plane['4'] = this.createTZPlane(
        this.pWidth,
        this.pHeight,
        this.segments,
        `/img/map/${this.getTZArt(this.currentMorningTZ.offset)}`,
        '0xfabe12'
      );

      this.plane['5'] = this.createTZPlane(
        this.pWidth,
        this.pHeight,
        this.segments,
        `/img/map/${this.getTZArt(this.currentMorningTZ.offset)}`,
        '0xfabe12'
      );
      this.plane['6'] = this.createTZPlane(
        this.pWidth,
        this.pHeight,
        this.segments,
        `/img/map/${this.getTZArt(this.currentMorningTZ.offset)}`,
        '0xfabe12'
      );

      this.plane['7'] = this.createTZPlane(
        this.pWidth,
        this.pHeight,
        this.segments,
        `/img/map/${this.getTZArt(this.currentEveningTZ.offset)}`,
        '0xef6952'
        // '0x549dff'
      );
      this.plane['8'] = this.createTZPlane(
        this.pWidth,
        this.pHeight,
        this.segments,
        `/img/map/${this.getTZArt(this.currentEveningTZ.offset)}`,
        '0xef6952'
      );
      this.plane['9'] = this.createTZPlane(
        this.pWidth,
        this.pHeight,
        this.segments,
        `/img/map/${this.getTZArt(this.currentEveningTZ.offset)}`,
        '0xfef6952'
      );

      this.plane['1'].position.set(-2.0, 0, 0);
      this.plane['3'].position.set(2.0, 0, 0);

      this.plane['4'].position.set(-2.0, 0, 0.0000000000015);
      this.plane['5'].position.set(0.0, 0, 0.0000000000015);
      this.plane['6'].position.set(2.0, 0, 0.0000000000015);

      this.plane['7'].position.set(-2.0, 0, 0.000000000002);
      this.plane['8'].position.set(0.0, 0, 0.000000000002);
      this.plane['9'].position.set(2.0, 0, 0.000000000002);

      this.plane['4'].name = 'morningtz1';
      this.plane['5'].name = 'morningtz2';
      this.plane['6'].name = 'morningtz3';
      this.plane['7'].name = 'eveningtz1';
      this.plane['8'].name = 'eveningtz2';
      this.plane['9'].name = 'eveningtz3';

      this.scene.add(this.plane['1']);
      this.scene.add(this.plane['2']);
      this.scene.add(this.plane['3']);

      this.scene.add(this.plane['4']);
      this.scene.add(this.plane['5']);
      this.scene.add(this.plane['6']);

      this.scene.add(this.plane['7']);
      this.scene.add(this.plane['8']);
      this.scene.add(this.plane['9']);

      // this.size = this.renderer.getSize();
      // this.reusableTarget = new THREE.WebGLRenderTarget(this.renderer.width, this.renderer.height);

      this.container.appendChild(this.renderer.domElement);
      this.onResize();
      this.orient = () => {
        setTimeout(this.onResize, 500);
      };
      window.addEventListener('deviceorientation', this.orient);
      window.addEventListener('resize', this.onResize.bind(this), false);

      this.render();
    },
    createPlane(pWidth, pHeight, segments) {
      var map = THREE.ImageUtils.loadTexture('/img/map/main_scene.png');
      var output = new THREE.Mesh(
        new THREE.PlaneGeometry(pWidth, pHeight, segments, segments),
        new THREE.MeshPhongMaterial({
          map: map,
          // bumpMap: bumpMap,
          // bumpScale: 0.000001,
          // specularMap: specularMap,
          specular: new THREE.Color('grey'),
        })
      );
      return output;
    },
    createTZPlane(pWidth, pHeight, segments, texture, color) {
      var map = THREE.ImageUtils.loadTexture(texture);
      var output = new THREE.Mesh(
        new THREE.PlaneGeometry(pWidth, pHeight, segments, segments),
        new THREE.MeshPhongMaterial({
          map: map,
          transparent: true,
          opacity: 1.0,
        })
      );
      output.material.color.setHex(color);
      return output;
    },
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;

      if (this.renderer) {
        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.width, this.height);
      }
    },
    render() {
      // controls.update();
      // clouds.rotation.y += 0.0005;
      requestAnimationFrame(this.render);
      // this.renderer.setRenderTarget(this.rtTexture);
      this.renderer.render(this.scene, this.camera);
    },
    onMouseMove() {
      // onMouseMove(evt) {
      // var mouse = new THREE.Vector2();
      // mouse.x = (evt.clientX / window.innerWidth) * 2 - 1;
      // mouse.y = -(evt.clientY / window.innerHeight) * 2 + 1;
      // var raycaster = new THREE.Raycaster();
      // raycaster.setFromCamera(mouse, this.camera);
      // var intersects = raycaster.intersectObjects(this.scene.children);
      // intersects.forEach((v) => {
      //   var tznames = ['morningtz1', 'morningtz2', 'morningtz3', 'eveningtz1', 'eveningtz2', 'eveningtz3'];
      //   if (tznames.includes(v.object.name)) {
      //       var target = this.renderer.getRenderTarget() || this.reusableTarget;
      //       this.size = new THREE.Vector2();
      //       this.renderer.getSize(this.size);
      //       console.log(this.size);
      //       target.setSize(this.size.width, this.size.height);
      //       this.renderer.setRenderTarget(target);
      //       var outputBuffer = new Uint8Array( 1 * 1 * 4 );
      //       this.renderer.readRenderTargetPixels( target, 0, 0, target.width, target.height, outputBuffer );
      //       var pixelIndex = ((mouse.y * target.width) + mouse.x) * 4;
      //       var color = {
      //         r: outputBuffer[pixelIndex + 0],
      //         g: outputBuffer[pixelIndex + 1],
      //         b: outputBuffer[pixelIndex + 2],
      //         a: outputBuffer[pixelIndex + 3]
      //       };
      //       console.log(color);
      //   }
      // });
      // // if (intersects.length > 0) {
      // //   document.body.style.cursor = 'pointer';
      // // } else {
      // //   document.body.style.cursor = 'default';
      // // }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/helpers';
@import '../styles/typovars';

#map {
  // background: #3e3e3e;
  background: rgba(63, 63, 63, 1);

  iframe {
    background-color: $ltgrey;
  }
}
svg {
  .st0 {
    fill: #fff;
  }
}
#map-div {
  margin: 0;
  padding: 0;
  height: 100%;
}
#welcome {
  top: 35%;
  transform: translateY(-50%);
  left: 10vw;
  p {
    @include fluid-type($minwidth, $medwidth, 13px, 15px);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05em;
  }
  h1 {
    @include fluid-type($minwidth, $medwidth, 44px, 47px);
    color: $blue;
    i {
      font-size: 35%;
      font-style: normal;
      line-height: 1em;
    }
    span {
      display: block;
      font-size: 35%;
      line-height: 1em;
    }
  }
  @media only screen and (max-width: $medwidth) {
    top: 170px;
    transform: none;
    left: 30px;
  }
}
#sessionData {
  z-index: 1000;
  bottom: 117px;
  width: 270px;
  #sessionMenu {
    a {
      cursor: pointer;
      font-weight: 700;
      text-align: center;
      @include fluid-type($minwidth, $maxwidth, 13px, 15px);

      display: inline-block;
      padding: 5px;
      color: $dkblue;
      background: #fff;

      &.active {
        background-color: $blue;
        color: #fff;
      }
      @media (hover: hover) {
        &:hover {
          background-color: $yellow;
        }
      }
    }
  }

  h4 {
    font-weight: 600;
    @include fluid-type($minwidth, $maxwidth, 14px, 16px);
  }

  h2 {
    @include fluid-type($minwidth, $maxwidth, 16px, 23px);
    margin-bottom: 10px;
  }
  p {
    color: $yellow;
    font-family: $sans;
    line-height: 1.15em;
    font-weight: 800;
    @include fluid-type($minwidth, $maxwidth, 14px, 16px);
    margin-bottom: 5px;
  }
  img {
    width: calc(270 / 1920 * 100vw);
    height: auto;
    max-width: 270px;
  }
}
#sessionInfoBtn {
  cursor: pointer;
}
</style>
