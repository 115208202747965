<template>
  <div id="container" class="relative w-screen">
    <Menu></Menu>
    <Map></Map>
    <router-view></router-view>
    <Legal></Legal>
  </div>
</template>
<script>
import Map from "@/components/map.vue";

import Menu from "./components/header.vue";
import Legal from "./components/legal.vue";
// import "tailwindcss/tailwind.css";
export default {
  components: {
    Menu,
    Legal,
    Map,
  },
  data() {
    return {
      currentComponent: "home",
      bundler: "Parcel",
    };
  },
  mounted() {
    //

    document.body.addEventListener(
      "mousedown",
      (() => {
        if (document.body.classList.contains("ada-keyboard-focus"))
          document.body.classList.remove("ada-keyboard-focus");
      }).bind(window)
    );

    document.body.addEventListener(
      "keydown",
      ((evt) => {
        const char = evt.keyCode;
        if (/20|9/i.test(char)) {
          document.body.classList.add("ada-keyboard-focus");
        }
      }).bind(window)
    );

    this.$store.watch(
      (state) => {
        return state.path;
      },
      ((newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.currentComponent = newVal;
          // this.$forceUpdate();
        }
      }).bind(this),
      {}
    );
  },
  watch: {},
  methods: {},
};
</script>
<style lang="scss">
body {
  *:focus {
    outline: none;
  }
  &.ada-keyboard-focus *:focus {
    outline: #101f39 solid 2px;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
