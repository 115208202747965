<template>
  <transition name="modal">
    <div
      class="modal pointer-events-auto fixed w-screen h-screen top-0 left-0 lg:flex items-center justify-center"
      :class="modalType"
    >
      <div class="modal-overlay absolute w-full h-full dkblue opacity-98" @click="$emit('close')"></div>
      <div class="modal-container relative display-flex w-full h-full mx-auto z-50 overflow-y-auto p-5 pb-0">
        <div class="relative modal-content h-full">
          <!--Title-->
          <div class="relative">
            <div class="relative text-right">
              <div class="modal-close relative cursor-pointer z-50 inline-block lg:float-right md:float-none">
                <a class="pointer-events-auto" @click="$emit('close')">
                  <svg
                    class="fill-current text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 18 18"
                  >
                    <path
                      d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
            <div class="relative modal-header lg:top-2 md:top-15 text-2xl font-bold text-center">
              <slot name="header">
                default header
              </slot>
            </div>
          </div>
          <!--Body-->
          <div class="modal-body mx-auto lg:flex md:block h-full md:pt-20 items-center justify-center">
            <slot name="body">
              default body
            </slot>
          </div>
          <!--Footer-->
          <div class="modal-footer w-12 text-center">
            <slot name="footer"></slot>
          </div>
          <!--  END FOOTER -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      show: false,
      modalType: 'page',
      // modalHTML: '',
      // modalContent: '',
      // showModal: false,
    };
  },
  computed: {},

  mounted() {},
  watch: {},
  methods: {
    keyDown: function(evt) {
      let char = evt.keyCode;

      if (/27/i.test(char)) {
        // if (/27|38|40/i.test(char)) {
        evt.preventDefault();
        evt.stopPropagation();

        switch (char) {
          case 27: //escape =
            this.$emit('close');
            break;
          default:
            break;
        }
      }
      // data.lastKey = char;
    },
  },
};
</script>
<style lang="scss">
@import '../styles/typovars';

.modal {
  transition: opacity 0.25s ease;
  z-index: 3000;

  #vframe {
    margin: auto;
    text-align: center;
    position: relative;
    width: 82vw;
    height: calc(82vw * 0.5625);
    // transform: translateY(-50%);

    // @media screen and (min-aspect-ratio: 93/56) {
    //   // height: calc(100% - 40px);
    //   height: calc(100vh - 100px);
    //   top: 50px;
    //   transform: none;
    // }
  }
}
.modal-header {
  color: $yellow;
}
body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}
</style>
