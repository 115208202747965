<template>
  <div id="home" class="relative pointer-events-none">
    <section
      v-show="loaded"
      id="mainContent"
      class="lg:relative w-screen lg:flex md:block items-center pointer-events-none"
    >
      <div
        id="slide0"
        class="slide relative w-full lg:flex md:block items-center pointer-events-none"
        :class="{ activeslide: count == 0 }"
      >
        <div class="contentHolder w-screen pointer-events-auto md:p-10 p-5 bg-white bg-opacity-90">
          <h1 class="text-center serif text-evening" v-html="pageData.Headline_LO"></h1>
          <p class="text-center sans text-dkblue text-xl m-auto">
            Global Peace Practice is a daily endeavor to create positive energy for peace
          </p>
        </div>
      </div>
      <div
        id="slide1"
        class="slide relative w-full lg:flex md:block items-center pointer-events-none"
        :class="{ activeslide: count == 1 }"
      >
        <div class="contentHolder w-full pointer-events-auto md:p-10 p-5 bg-white bg-opacity-90">
          <h1 class="text-center serif text-evening" v-html="pageData.Slide_About_Headline"></h1>
          <div class="text-center sans text-dkblue" v-html="pageData.Slide_About_Content"></div>
        </div>
      </div>
      <div
        id="slide2"
        class="slide relative w-full lg:flex md:block items-center pointer-events-none md:p-10 p-5 bg-white bg-opacity-90"
        :class="{ activeslide: count == 2 }"
      >
        <div class="contentHolder w-full pointer-events-auto">
          <h1 class="text-center serif text-evening" v-html="pageData.Slide_Contact_Headline"></h1>
          <div class="text-center sans text-dkblue" v-html="pageData.Slide_Contact_Content"></div>
          <div class="mt-8 m-auto text-center sans text-dkblue">
            <p class="my-5">Sign up for our mailing list to stay up to date.</p>
            <p><button class="button py-2 dkblue text-white" @click="modalClick">Sign Up</button></p>
          </div>
        </div>
      </div>
      <div id="arrowNav">
        <a
          v-show="this.count > 0"
          class="absolute top-1/2 left-10 arrow left opacity-80 p-5 cursor-pointer pointer-events-auto"
          @click.prevent="previous"
        >
          <img width="17" height="auto" src="../assets/img/home/sliderArrow.svg" alt="previous slide" />
        </a>
        <a
          v-show="this.count < this.numSlides - 1"
          class="absolute top-1/2 right-10 arrow right opacity-80 p-5 cursor-pointer pointer-events-auto"
          @click.prevent="next"
        >
          <img width="17" height="auto" src="../assets/img/home/sliderArrow.svg" alt="next slide" />
        </a>
      </div>
    </section>
    <div id="legend" class="absolute bottom-5 left-5 inline-block pointer-events-none text-center m-auto">
      <div class="inline-block text-left">
        <p class="text-evening sans font-bold sm:text-sm">
          <span class="evening inline-block p-2 mr-1"></span>9pm (21.00)
        </p>
        <p class="text-yellow sans font-bold sm:text-sm"><span class="yellow inline-block p-2 mr-1"></span>7am</p>
      </div>
    </div>
    <!--Modal-->
    <Modal v-if="showModal" @close="showModal = false">
      <span slot="header" v-html="modalProps.title"></span>
      <div slot="body" class="w-full m-auto">
        <emailsignup></emailsignup>
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from "axios";
import emailsignup from "../components/emailsignup.vue";
import Modal from "../components/modal.vue";

import "tailwindcss/tailwind.css";
// import { gsap, Linear } from 'gsap';
// import ScrollTrigger from 'gsap/ScrollTrigger';

export default {
  components: { emailsignup, Modal },
  data() {
    return {
      tl: null,
      loaded: false,
      showModal: false,
      prev: 0,
      count: 0,
      numSlides: 3,
      pageData: {},
      loggedIn: false,
      modalProps: {},
    };
  },
  created() {
    /*
    axios
      .get(this.$store.state.api + 'about-us/')
      .then((response) => {
        console.log(`about us: ${JSON.stringify(response.data)}`);
      })
      .catch((error) => console.log(error));

*/

    // LOAD FROM API
    axios
      .get(this.$store.state.api + "home-page/")
      .then((response) => {
        // console.log(response);
        this.pageData = response.data;
        this.loaded = true;
        this.$forceUpdate();
      })
      .catch((error) => console.log(error));
  },
  mounted() {
    // gsap.registerPlugin(ScrollTrigger);
    this.loggedIn = this.$store.state.user.authenticated;
  },
  watch: {
    /* */
  },
  methods: {
    previous() {
      this.prev = this.count;
      this.count--;
      this.scroll("previous");
    },
    next() {
      this.prev = this.count;
      this.count++;
      this.scroll("next");
    },
    scroll(position) {
      // if (this.animating) return;
      // this.animating = true;
      // var screenW = window.innerWidth;
      var next = this.count;
      // var inStart, outEnd;
      if (position == "next") {
        next = next >= this.numSlides ? 0 : next;
        // inStart = screenW;
        // outEnd = -screenW;
      } else {
        next = next < 0 ? this.numSlides - 1 : next;
        // inStart = -screenW;
        // outEnd = screenW;
      }
      this.count = next;
      // var outEl = document.getElementById('slide' + this.prev);
      // var inEl = document.getElementById('slide' + next);
      // console.log('this.prev', this.prev);
      // console.log('next', next);
      // outEl.classList.add('transition');
      // inEl.classList.add('transition');

      // var tl = gsap.timeline({ paused: true, onComplete: onComplete.bind(this) });
      // tl.fromTo(inEl, 0.65, { autoAlpha: 0 }, { autoAlpha: 1, ease: Linear.easeNone }, 0).fromTo(
      //   outEl,
      //   0.65,
      //   { autoAlpha: 1 },
      //   { autoAlpha: 0, ease: Linear.easeNone },
      //   0.15
      // );
      // // .fromTo(inEl, 1.25, { x: inStart }, { x: 0, force3d:true, ease: Power4.easeOut }, 0)
      // // .fromTo(outEl, 1.25, { x: 0 }, { x: outEnd, force3d:true, ease: Power4.easeOut }, 0);
      // tl.restart();
      // function onComplete() {
      //   outEl.classList.remove('transition');
      //   outEl.classList.remove('activeslide');
      //   inEl.classList.remove('transition');
      //   inEl.classList.add('activeslide');
      //   this.count = next;
      //   this.animating = false;
      //   console.log('onComplete');
      // }
    },
    modalClick: function(e) {
      this.showModal = true;
    },
  },
};
</script>
<style lang="scss" scope>
// @import 'tailwindcss/base';
@import "../styles/helpers";
@import "../styles/typovars";
#home {
  #mainContent {
    overflow: hidden;
    overflow-y: visible;
    margin: auto;
    display: flex;
    min-height: 100vh;
    padding: 0px 10vw;
    max-width: 1440px;
    // min-height: 700px;

    .contentHolder {
      transform-origin: top left;
      margin: auto;
      // max-width: 780px;
      h1 {
        font-weight: 600;
        @include fluid-type($minwidth, $medwidth, 30px, 48px);
        // margin-bottom: 0.25em;
      }
      h2 {
        @include fluid-type($minwidth, $medwidth, 18px, 24px);
        letter-spacing: 0.075em;
      }
      p {
        margin-bottom: 1em;
      }
      // @media only screen and (min-height: 1000px) {
      //   transform: scale(1.15);
      // }
    }

    @media only screen and (max-width: $minwidth) {
      padding: 130px 14vw 175px;
      height: initial;
      margin-top: -116px;

      button {
        padding: 5px;
        // margin:0px;
        min-width: initial;
      }
      br {
        display: none;
      }
    }
  }
  .st0 {
    fill: #fff;
  }

  .inspirationcta {
    @media only screen and (max-width: $minwidth) {
      // padding-top: 140px;
    }
  }
  #legend {
    @media only screen and (max-width: $minwidth) {
      bottom: initial;
      top: 87%;
    }
  }
  //SLIDER SHIT
  #arrowNav {
    z-index: 1000;
    .arrow {
      top: 45vh;
    }
    .left {
      transform: scaleX(-1);
      left: 0.5vw;
    }
    .right {
      right: 0.5vw;
    }
    @media (hover: hover) {
      .arrow {
        transition: opacity 0.3s ease;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .slide {
    display: none;
    opacity: 0;
    &.activeslide {
      display: inherit;
      opacity: 1;
    }
    &.transition {
      display: inherit;
      position: absolute;
    }
  }
}
</style>
