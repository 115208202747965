<template>
  <header
    class="lg:fixed md:fixed w-screen left-0 top-0 pointer-events-none"
    :class="{ secondary: showSecondaryHeader, dkblue: showSecondaryHeader, showListHeader: showListHeader }"
  >
    <div id="logo" class="absolute pointer-events-auto">
      <router-link to="/">
        <img
          src="../assets/img/gpp_logo_stack_white.svg"
          class="user-select-none select-none"
          alt="Global Peace Practice"
        />
      </router-link>
    </div>
    <nav id="mainNav" class="absolute sans pointer-events-auto">
      <router-link to="/learn-more" :class="{ selected: currentRouteName == 'learn-more' }">
        LEARN MORE
      </router-link>
      <router-link to="/inspiration" :class="{ selected: currentRouteName == 'inspiration' }">
        INSPIRATION
      </router-link>
    </nav>
  </header>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loggedIn: false,
      bundler: "Parcel",
    };
  },
  mounted() {},
  computed: {
    currentRouteName() {
      return this.$route.name;
    },

    showSecondaryHeader() {
      return !["home", "logout", "login", "signup", "lost", "learn-more", "inspiration", "favorites"].includes(
        this.currentRouteName
      );
    },
    showListHeader() {
      return ["inspiration", "favorites", "learn-more"].includes(this.currentRouteName);
    },
  },
  watch: {
    $route(newVal, oldVal) {
      if (newVal.name !== oldVal.name) {
        // this.closeHamburger();
        this.$forceUpdate();
      }
    },
  },
  methods: {
    checkSession: function() {},

    toggleLoggedIn() {
      this.$store.dispatch("setUserStatus", { target: "authenticated", status: !this.loggedIn });
    },
    toggleHamburger() {
      const hamburger = document.querySelector("#hamburger");
      const hamburgerNav = document.querySelector("#secondaryNav");
      hamburger.classList.toggle("menu-open");
      document.body.classList.toggle("menu-open");
      hamburgerNav.classList.toggle("opacity-0");
      hamburgerNav.classList.toggle("invisible");
      hamburgerNav.classList.toggle("menu-open");
    },
    closeHamburger() {
      const hamburger = document.querySelector("#hamburger");
      const hamburgerNav = document.querySelector("#secondaryNav");
      if (hamburger.classList.contains("menu-open")) {
        hamburger.classList.remove("menu-open");
        document.body.classList.remove("menu-open");
        hamburgerNav.classList.remove("menu-open");
        hamburgerNav.classList.add("opacity-0");
        hamburgerNav.classList.add("invisible");
      }
    },
    resetState() {},
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/helpers";
@import "../styles/typovars";
// .beta {
//   transform-origin: top right;
//   transform: scale(1.75);
// }
header {
  z-index: 300;
  top: 0px;
  left: 0px;
  width: 100vw;
  min-height: 120px;
  // @media only screen and (max-width: $medwidth) {
  //   min-height: 116px;
  // }

  background: rgb(16, 31, 57);
  background: linear-gradient(0deg, rgba(51, 81, 117, 0) 0%, rgba(16, 31, 57, 0.75) 100%);
  // transition: background-color 0.15s ease-out;
}
#logo {
  left: 39px;
  top: 33px;
  width: 170px;
  height: 80px;
  display: flex;
  a {
    display: flex;
  }
  img {
    z-index: 2999;
    max-width: 172px;
    min-width: 130px;
    width: calc(180 / 1920 * 100vw);
    height: auto;
  }
  @media only screen and (max-width: $medwidth) {
    left: 5.125vw;
    top: 4vw;
    img {
      min-width: 105px;
    }
  }
}
nav {
  a {
    position: relative;
    display: block;
    font-family: $sans;
    font-size: 14px;
    @include fluid-type($minwidth, $maxwidth, 12px, 14px);

    color: rgb(254, 254, 254);
    font-weight: bold;
    letter-spacing: 0.03em;
  }
}
#mainNav {
  top: 63px;
  right: 45px;
  z-index: 20;

  .menu-open & {
    visibility: hidden;
  }
  a {
    display: inline-block;
    margin-left: 31px;
    &:first-child {
      margin-left: 0px;
    }

    @media (hover: hover) {
      &:hover {
        &:after {
          content: " ";
          position: absolute;
          top: 100%;
          left: 0px;
          display: block;
          width: 100%;
          height: 3px;
          background-color: $yellow;
        }
      }
    }

    &.selected {
      color: $yellow;
    }
  }

  @media only screen and (max-width: $medwidth) {
    top: 9vw;
    right: 6vw;

    a {
      margin-left: 15px;
    }
  }
}
#hamburger {
  transform-origin: top right;
  top: 58px;
  right: 50px;
  width: 40px;
  height: 30px;
  z-index: 10;
  cursor: pointer;
  .bg {
    cursor: pointer;
  }
  span {
    background-color: #fff;
    transform-origin: center center;
    width: 100%;
    height: 4px;
    transition: all 0.22s ease-out;
    &.mid {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.menu-open {
    .top-0 {
      transform: translateY(7px) rotate(45deg);
    }
    .mid {
      opacity: 0;
    }
    .bottom-0 {
      transform: translateY(-19px) rotate(-45deg);
    }
    @media only screen and (max-width: $medwidth) {
      .top-0 {
        transform: translateY(50%) rotate(45deg);
      }

      .bottom-0 {
        transform: translateY(-20px) rotate(-45deg);
      }
    }
  }
  @media (hover: hover) {
    &:hover {
      span {
        background: $blue;
      }
    }
  }
  @media only screen and (max-width: $medwidth) {
    top: 10vw;
    right: 8vw;
    width: 35px;
    height: 26px;
  }
}
#secondaryNav {
  top: 0px;
  right: 0px;
  width: 100%;
  max-width: 25vw;
  height: 100vh;
  padding-top: 130px;
  transition: opacity 0.3s ease-out;
  &.menu-open {
    min-width: 280px;
  }

  a {
    padding: 2vh 0px;
    display: block;
    font-size: 24px;
    @media (hover: hover) {
      &:hover {
        color: $yellow;
      }
    }
    &.selected {
      color: $yellow;

      // &:after {
      //   content: ' ';
      //   position: absolute;
      //   top: 100%;
      //   left: 0px;
      //   display: block;
      //   width: 100%;
      //   height: 3px;
      //   background-color: $yellow;
      // }
    }
  }
  @media only screen and (max-width: $medwidth) {
    max-width: 100vw;
  }
}
#social {
  a {
    display: inline-block;
  }
}
#container {
  &:after {
    content: " ";
    opacity: 0;
    transition: opacity 0.15s ease-out;
  }
  .menu-open & {
    &:after {
      position: fixed;
      background-color: $dkblue;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      opacity: 0.8;
    }
  }
}
</style>
