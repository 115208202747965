import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    aboutAudioPlayed:false,
    modalActive:false,
    timezoneOffset:0,
    timezone:'',
    path: 'home',
    authURL:`${window.location.protocol}//admin.globalpeacepractice.org/auth/local`,
    api:`${window.location.protocol}//admin.globalpeacepractice.org/`,
    // authURL:`${window.location.protocol}//${window.location.hostname}/api/auth/local`,
    // api:`${window.location.protocol}//${window.location.hostname}/api/`,
    // authURL:'http://165.22.4.29/auth/local',
    // api: 'http://165.22.4.29/',
    user: {
      authenticated: false,
      usernname: '',
      firstname: '',
      lastname: '',
      timezone: '',
      token:''
    },
  },

  getters: {
    userInfo: (state) => (target) => {
      return state.user[target];
    },
  },

  mutations: {
    setUserStatus(state, payload) {
      state.user[payload.target] = payload.status;
    },
    setState(state, payload) {
      state[payload.target] = payload.value;
    },
  },

  actions: {
    setUserStatus(context, payload) {
      context.commit('setUserStatus', payload);
    },
    setState(context, payload) {
      context.commit('setState', payload);
    },
  },
});
