<template>
  <div class="relative w-full flex">
    <!-- <iframe
      src="./email.html"
      width="100%"
      height="590"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      >Loading…</iframe
    > -->
    <iframe
      id="formFrame"
      src="https://docs.google.com/forms/d/e/1FAIpQLSc4oTt_kW5MbWzvMI3jJMHSa7_EkIEvWKfEl3LZIKEIz3peoA/viewform?embedded=true&footer=false&footers=false&chrome=false"
      width="100%"
      height="590"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      >Loading…</iframe
    >
  </div>
</template>

<script>
// import CustomInput from "./custominput.vue";
export default {
  // components: { CustomInput },
  props: {},
  data() {
    return {
      email: "",
    };
  },
  mounted: function() {
    // var formFrame = document.querySelector('#formFrame');
    //   console.log(formFrame);
    // setTimeout(function(){
    //   let doc = formFrame.contentDocument;
    //   console.log(doc);
    //   doc.body.innerHTML = doc.body.innerHTML + '<style>.freebirdFormviewerViewFooterEmbeddedBackground { display:none !important;}</style>';
    // },500);

  },
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
iframe {
  min-width: 600px;
  @media only screen and (max-width: 650px) {
    min-width: 100%;
  }
}
label {
  color: #fff;
}
</style>
