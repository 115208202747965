import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: Home
  // },
  {
    path: '/favorites',
    name: 'favorites',
    /* webpackChunkName: "favorites" */
    component: () => import('../views/favorites.vue'),
    meta: { requiresLogin: true },
  },
  {
    path: '/inspiration',
    name: 'inspiration',
    /* webpackChunkName: "inspiration" */
    component: () => import('../views/inspiration.vue'),
    meta: { requiresLogin: true },
  },
  {
    path: '/logout',
    name: 'logout',
    /* webpackChunkName: "logout" */
    component: () => import('../views/logout.vue'),
    meta: { requiresLogin: true },
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   /* webpackChunkName: "login" */
  //   component: () => import('../views/login.vue'),
  //   meta: { requiresLogin: false },
  // },
  // {
  //   path: '/faq',
  //   name: 'faq',
  //   /* webpackChunkName: "faq" */
  //   component: () => import('../views/faq.vue'),
  //   meta: { requiresLogin: false },
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   /* webpackChunkName: "about" */
  //   component: () => import('../views/about.vue'),
  //   meta: { requiresLogin: false },
  // },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   /* webpackChunkName: "contact" */
  //   component: () => import('../views/contact.vue'),
  //   meta: { requiresLogin: false },
  // },
  {
    path: '/donate',
    name: 'donate',
    /* webpackChunkName: "donate" */
    component: () => import('../views/donate.vue'),
    meta: { requiresLogin: false },
  },
  {
    path: '/learn-more',
    name: 'learn-more',
    /* webpackChunkName: "donate" */
    component: () => import('../views/learnmore.vue'),
    meta: { requiresLogin: false },
  },
  {
    path: '/settings',
    name: 'settings',
    /* webpackChunkName: "settings" */
    component: () => import('../views/settings.vue'),
    meta: { requiresLogin: true },
  },
  {
    path: '/upcoming-features',
    name: 'upcoming-features',
    /* webpackChunkName: "settings" */
    component: () => import('../views/terms.vue'),
    meta: { path: 'upcoming-features' },
  },
  {
    path: '/signup',
    name: 'signup',
    /* webpackChunkName: "signup" */
    component: () => import('../views/signup.vue'),
  },
  {
    path: '/signedup',
    name: 'signedup',
    /* webpackChunkName: "signedup" */
    component: () => import('../views/signedup.vue'),
    meta: { requiresLogin: true },
  },
  {
    path: '/connect/cognito/redirect',
    name: 'redirect',
    /* webpackChunkName: "redirect" */
    component: () => import('../views/redirect.vue'),
    meta: { requiresLogin: true },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    /* webpackChunkName: "privacy-policy" */
    component: () => import('../views/terms.vue'),
    meta: { path: 'privacy-policy' },
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    /* webpackChunkName: "terms-and-conditions" */
    component: () => import('../views/terms.vue'),
    meta: { path: 'terms-and-conditions' },
  },
  {
    path: '/forgot-password',
    name: 'lost',
    /* webpackChunkName: "lostpassword" */
    component: () => import('../views/lostpassword.vue'),
    meta: { requiresLogin: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
