import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'tailwindcss/tailwind.css';
import './index.scss';
import AudioVisual from 'vue-audio-visual'
import VueHorizontal from 'vue-horizontal';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
Vue.use(AudioVisual);
Vue.use(VueHorizontal);