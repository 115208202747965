<template>
  <footer class="w-screen absolute bottom-0 left-0">
    <div id="legal" class="text-white pt-2">
      <p class="sans text-xs text-center">
        ®2021 Global Peace Practice, a product of The Peace Atlas. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loggedIn: false,
      currentComponent: 'menu',
      bundler: 'Parcel',
    };
  },
  mounted() {
    this.$store.watch(
      (state) => {
        return state.user.authenticated;
      },
      ((newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.loggedIn = newVal;
          // console.log(newVal);
          // this.resetState();
          // this.setFlowEnvironment(newVal);
          this.$forceUpdate();
        }
      }).bind(this),
      {}
    );
  },
  watch: {},
  methods: {
    resetState: function() {},
  },
};
</script>
<style lang="scss" scoped>
@import '../styles/typovars';

footer {
  // background-color: #444548;
  width: 100%;
  z-index: 300;
  height: 30px;
  margin: 0;
  overflow: visible;
    @media only screen and (max-width: $minwidth) {
    bottom: 20px !important;
  }

}
#legal {
  width: 100%;
  p {
    font-size: 9px;
    padding-right: 20px;
    opacity: 0.2;

    @media only screen and (max-width: $minwidth) {
      padding: 1px;
      text-align: center;
    }
  }
}
#social {
  a {
    display: inline-block;
  }
}
</style>
